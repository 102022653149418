import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import verificareToken from './VerificareToken';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Autentificare } from './autentificare';
import ResetareParola from './ResetareParola';
import { Licentiere } from './licentiere';
import Setari from './SetariAplicatie.js';
import { Panou } from './panou';
import ListaDosare from './listaDosare';
import DocPartajare from './DocPartajate.js';
import ListaDosareArhivate from './listaDosareArhivate';
import { Delogare } from './logout';
import EditareDosar from './dosar/editareDosar';
import UserPrefs from './utilizator/preferinteUtilizator';
import CalendarEvenimenteMultiple from './CalendarEvenimente';
import CautareOnline from './CautareOnline.js';
import Rapoarte from './Rapoarte';
import RaportPivot from './RapoartPivot.js';
import Menu from './Menu';
import Footer from './Footer';
import AdministrareUtilizatori from './AdministrareUtilizatori';
import GrupuriDosare from './grupuriDosare/GrupuriDosare';
import ProfilUtilizator from './utilizator/ProfilUtilizator'; 
import RecuperareDosareSterse from './RecuperareDosareSterse.js';
import BackupRestore from './BackupRestore.js';
import ExportDownload from './ExportDownload.js';
import SincronizareImediata from './SincronizareImediata.js'; 

import { SoundProvider } from './sunete';
import GlobalEventListener from './GlobalEventListener';

import './fonts/font-awesome-4.7.0/css/font-awesome.min.css';
import './fonts/iconic/css/material-design-iconic-font.min.css';
import './css/spectrum.min.css'; 
 
const RequireAuth = ({ children }) => {
  const { token, ok2goval } = verificareToken();

  if (!token) {
    return <Navigate to="/autentificare?401" />;
  }

  if (ok2goval === 'false' && window.location.pathname !== '/licentiere') {
    window.location.href = '/licentiere';
    return null;
  }

  return children;
};
  

function getAndSetGlobalVariable(key) {
  // Funcție pentru a obține valoarea din cookie
  const getCookieValue = (cookieName) => {
      const cookies = document.cookie.split('; ');
      for (let cookie of cookies) {
          const [name, value] = cookie.split('=');
          if (name === cookieName) {
              return decodeURIComponent(value);
          }
      }
      return null;
  };

  // Obține valoarea din cele trei locații
  const fromCookie = getCookieValue(key);
  const fromLocalStorage = localStorage.getItem(key);
  const fromSessionStorage = sessionStorage.getItem(key);

  // Determină valoarea finală (prioritate: sessionStorage > localStorage > cookie)
  const finalValue = fromSessionStorage || fromLocalStorage || fromCookie;

  // Setează valoarea globală (chiar dacă este null)
  window[key] = finalValue !== null ? finalValue : null; // Setează variabila globală

  // Returnează valoarea finală (sau null dacă nu a fost găsită)
  return finalValue;
}

// Exemplu de utilizare:
const denconsValue = getAndSetGlobalVariable('dencons');
console.log('Valoarea globală pentru dencons:', window.dencons);


// Capturăm erorile `ResizeObserver` și le ignorăm
const resizeObserverLoopErr = /(ResizeObserver loop limit exceeded|ResizeObserver loop completed with undelivered notifications)/;

window.addEventListener("error", (e) => {
  if (resizeObserverLoopErr.test(e.message)) {
    e.stopImmediatePropagation();
  }
});

window.addEventListener("unhandledrejection", (e) => {
  if (resizeObserverLoopErr.test(e.reason?.message)) {
    e.preventDefault();
  }
});


const root = ReactDOM.createRoot(document.getElementById('root'));
const menu = ReactDOM.createRoot(document.getElementById('menu'));
const footer = ReactDOM.createRoot(document.getElementById('footer'));

root.render(
  <React.StrictMode>
  <BrowserRouter>
  <SoundProvider>
    <GlobalEventListener />
    <Routes>
      <Route exact path='/' element={ <App /> } />
      <Route exact path='/autentificare' element={<Autentificare />} />
      <Route exact path='/resetare-parola' element={<ResetareParola />} />
      <Route path="/resetare-parola/:token" element={<ResetareParola />} />
      <Route exact path='/licentiere' element={
        <RequireAuth>
          <Licentiere />
        </RequireAuth>
      } />
      <Route exact path='/setari' element={
        <RequireAuth>
          <Setari />
        </RequireAuth>
      } />
      <Route exact path='/panou' element={
        <RequireAuth>
          <Panou />
        </RequireAuth>
      } />
      <Route exact path='/lista-dosare' element={
        <RequireAuth>
          <ListaDosare />
        </RequireAuth>
      } /> 
      <Route exact path='/lista-dosare-arhivate' element={
        <RequireAuth>
          <ListaDosareArhivate />
        </RequireAuth>
      } /> 
      <Route path='/editare-dosar/:id' element={
        <RequireAuth>
          <EditareDosar />
        </RequireAuth>
      } />
      <Route exact path='/logout' element={
        <RequireAuth>
          <Delogare />
        </RequireAuth>
      } /> 
      <Route exact path='/preferinte-utilizator' element={
        <RequireAuth>
          <UserPrefs />
        </RequireAuth>
      } /> 
      <Route exact path='/calendar' element={
        <RequireAuth>
          <CalendarEvenimenteMultiple />
        </RequireAuth>
      } /> 
      <Route exact path='/cautare-online' element={
        <RequireAuth>
          <CautareOnline />
        </RequireAuth>
      } /> 
      <Route exact path='/rapoarte' element={
        <RequireAuth>
          <Rapoarte />
        </RequireAuth>
      } /> 
      <Route exact path='/raport-pivot' element={
        <RequireAuth>
          <RaportPivot />
        </RequireAuth>
      } />  
      <Route exact path='/administrare-utilizatori' element={
        <RequireAuth>
          <AdministrareUtilizatori />
        </RequireAuth>
      } /> 
      <Route exact path='/grupuri-dosare' element={
        <RequireAuth>
          <GrupuriDosare />
        </RequireAuth>
      } /> 
      <Route exact path='/profil-utilizator' element={
        <RequireAuth>
          <ProfilUtilizator />
        </RequireAuth>
      } /> 
      <Route exact path='/recuperare-dosare' element={
        <RequireAuth>
          <RecuperareDosareSterse />
        </RequireAuth>
      } /> 
      <Route exact path='/backup-restaurare' element={
        <RequireAuth>
          <BackupRestore />
        </RequireAuth>
      } /> 
      <Route exact path='/export-dosare' element={
        <RequireAuth>
          <ExportDownload />
        </RequireAuth>
      } /> 
      <Route exact path='/sincronizare-dosare' element={
        <RequireAuth>
          <SincronizareImediata />
        </RequireAuth>
      } /> 
      <Route path='/docPartajare/:cheie' element={ 
          <DocPartajare /> 
      } />
    </Routes>
    </SoundProvider>
  </BrowserRouter>
</React.StrictMode>
);

menu.render(
  <React.StrictMode>
    <Menu />
  </React.StrictMode>
);

footer.render(
  <React.StrictMode>
    <Footer />
  </React.StrictMode>
);
 
reportWebVitals();