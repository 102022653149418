import React, { useState, useEffect } from 'react'; 
import $ from 'jquery';
import verificareToken from './VerificareToken';
import { initializeApp } from "firebase/app";
import SchimbareLicenta from './SchimbareLicenta';
import { Button, Alert } from '@mui/material';
import { isIOS } from 'react-device-detect';


import DrepturiUtilizator from './utilizator/drepturiUtilizator';  
import { getMessaging, getToken, deleteToken } from 'firebase/messaging'; 
export const Licentiere = () => {
  const [username, setUsername] = useState(''); 
  
  //modala Licenta
  const [openDialogLicenta, setOpenDialogLicenta] = useState(false);
  const handleOpenDialogLicenta = () => setOpenDialogLicenta(true);
  const handleCloseDialogLicenta = () => setOpenDialogLicenta(false);

  //reactualizez starea lui authChange - verific starea lui isLoggedIn sa vad daca mai e logat
  window.dispatchEvent(new Event('authChange'));

    const { token } = verificareToken();

  
    useEffect(() => {
      const fetchDenumire = async () => {
        try {
          const response = await fetch(`${window.API_URL}get-denumire`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ den: 'dosarcons' }),
          });
    
          if (!response.ok) {
            throw new Error(`Eroare API: ${response.status}`);
          }
    
          const data = await response.json();
    
          if (data.success && data.denumire) {
            const denumire = data.denumire;
    
            // Setăm în cookie, localStorage și sessionStorage
            document.cookie = `dencons=${denumire}; path=/; max-age=86400;`;
            localStorage.setItem('dencons', denumire);
            sessionStorage.setItem('dencons', denumire);
          } else {
            console.warn('Denumirea este null sau nu a fost găsită.');
          }
        } catch (error) {
          console.error('Eroare la preluarea denumirii:', error);
        }
      };
    
      fetchDenumire();
    }, [token]);

    
    const navigate = (url) => {
      window.location.href = url;
    };
  const [licenseData, setLicenseData] = useState({}); 
  const [error, setError] = useState(null);
  const [licenseMessage, setLicenseMessage] = useState(''); 
  const [butonActivare, setButonActivare] = useState(false);  
  const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
  };
 
 
  const getMachineId = () => {
    let machineId = localStorage.getItem('MachineId');

    if (!machineId) {
        machineId = crypto.randomUUID();
        localStorage.setItem('MachineId', machineId);
    }

    return machineId;
  };

  const getBrowserInfo = () => {
    const userAgent = navigator.userAgent;
    let browserName, fullVersion;

    const browserMatch = userAgent.match(/(firefox|msie|trident|chrome|safari|opr|edg|edge|opera|samsungbrowser|ucbrowser|baidubrowser|qihoobrowser|qqbrowser|brave|vivaldi)\/?\s*([\d.]+)/i) || [];
    const browser = browserMatch[1] || "";
    fullVersion = browserMatch[2] || "Unknown";

    switch (browser.toLowerCase()) {
        case 'firefox': browserName = 'Mozilla Firefox'; break;
        case 'msie':
        case 'trident': browserName = 'Microsoft Internet Explorer'; break;
        case 'chrome': browserName = 'Google Chrome'; break;
        case 'safari': browserName = 'Apple Safari'; break;
        case 'opr': browserName = 'Opera'; break;
        case 'edg':
        case 'edge': browserName = 'Microsoft Edge'; break;
        case 'samsungbrowser': browserName = 'Samsung Internet'; break;
        case 'ucbrowser': browserName = 'UC Browser'; break;
        case 'baidubrowser': browserName = 'Baidu Browser'; break;
        case 'qihoobrowser': browserName = 'Qihoo Browser'; break;
        case 'qqbrowser': browserName = 'QQ Browser'; break;
        case 'brave': browserName = 'Brave'; break;
        case 'vivaldi': browserName = 'Vivaldi'; break;
        default: browserName = 'Unknown Browser'; break;
    }

    return { browserName, fullVersion };
};

const getOSInfo = () => {
  const userAgent = navigator.userAgent;
  let os = 'Unknown';
  let osVersion = 'Unknown';

  if (userAgent.indexOf("Windows NT 10.0") !== -1) {
      os = "Windows";
      osVersion = "10";
  } else if (userAgent.indexOf("Windows NT 11.0") !== -1) {
      os = "Windows";
      osVersion = "11";
  } else if (userAgent.indexOf("Windows NT 6.3") !== -1) {
      os = "Windows";
      osVersion = "8.1";
  } else if (userAgent.indexOf("Windows NT 6.2") !== -1) {
      os = "Windows";
      osVersion = "8";
  } else if (userAgent.indexOf("Windows NT 6.1") !== -1) {
      os = "Windows";
      osVersion = "7";
  } else if (userAgent.indexOf("Windows NT 6.0") !== -1) {
      os = "Windows";
      osVersion = "Vista";
  } else if (userAgent.indexOf("Windows NT 5.1") !== -1) {
      os = "Windows";
      osVersion = "XP";
  } else if (userAgent.indexOf("Windows NT 5.0") !== -1) {
      os = "Windows";
      osVersion = "2000";
  } else if (userAgent.indexOf("Mac") !== -1) {
      os = "Mac/iOS";
  } else if (userAgent.indexOf("X11") !== -1) {
      os = "UNIX";
  } else if (userAgent.indexOf("Linux") !== -1) {
      os = "Linux";
  }

  return { os, osVersion };
};

  // Utilizare
  const browserInfo = getBrowserInfo();
  const osInfo = getOSInfo();
 
//console.log('firebaseConfig', firebaseConfig);
useEffect(() => {
  if (!isIOS) {
      const app = initializeApp(firebaseConfig);
      const messaging = getMessaging(app);
      const requestAndSaveToken = () => {
          Notification.requestPermission().then((permission) => {
              if (verificareToken()) {
                  if (permission === "granted") {
                      console.log("Permisiune pentru notificări acordată.");

                      getToken(messaging, { vapidKey: process.env.REACT_APP_VAPIDKEY })
                          .then((currentToken) => {
                              if (currentToken) {
                                  console.log('Token client obținut: ', currentToken);


                                  const updateToken = async () => {
                                    try {
                                      // Obținerea noului token
                                      const newToken = await deleteToken(messaging).then(() => {
                                        return getToken(messaging, { vapidKey: process.env.REACT_APP_VAPIDKEY });
                                      });
                                  
                                      console.log("Noul token: ", newToken);
                                  
                                      const machineId = getMachineId();  
                                      const dateBrowserOs = `Browser ${browserInfo.browserName} ${browserInfo.fullVersion} / Sistem operare: ${osInfo.os} ${osInfo.osVersion}`;
 
                                      //console.log('deviceInfo', deviceInfo);
                                      const responseToken = await fetch(`${window.API_URL}updateFcmToken?fcm_token=${newToken}&app_identifier=${machineId}&device_info=${dateBrowserOs}`, {
                                        method: 'PATCH',
                                        headers: {
                                          'Content-Type': 'application/json',
                                          Authorization: `Bearer ${token}`,
                                        },
                                        //body: JSON.stringify(deviceInfo)  
                                      });
                                  
                                      if (responseToken.ok) {
                                        //const resultToken = await responseToken.json();
                                        //console.log('resultToken token backend', resultToken);
                                      } else {
                                        console.error("Eroare la setare tokenului in backend");
                                      }
                                    } catch (error) {
                                      console.error("Eroare la reînnoirea tokenului: ", error);
                                    }
                                  };


                                  updateToken();

                              } else {
                                  console.log('Nu s-a putut genera un token pentru aplicație.');
                              }
                          })
                          .catch((err) => {
                              console.log('Eroare la obținerea tokenului.', err);
                          });
                  } else {
                      console.log("Permisiunea utilizatorului a fost refuzată.");
                  }

              } else {
                  console.log("Utilizatorul nu este autentificat. Nu se reinnoieste tokenul.");
              }

          });
      };

      requestAndSaveToken();
  } else {
      console.log("iOS devices do not support Firebase Messaging through web browsers.");
  }
}, [isIOS]); 

  const fetchData = async () => {
    try {
      const response = await fetch(`${window.API_URL}getLicense`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
  
      if (!response.ok) {
        throw new Error(`API request failed with status ${response.status}`);
      }
  
      const data = await response.json();
      setLicenseData(data.data);
      //console.log('[Api 1] -> preiau licenta din baza de date:', data);
  
      if (data.data?.licenta) {
        const secondResponse = await fetch(`${window.API_URL}verifyLicense`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
          body: JSON.stringify({
            licenta: data.data.licenta?.serialno,
          }),
        });
  
        if (!secondResponse.ok) {
          throw new Error(`Requestul API nu merge. Status: ${secondResponse.status}`);
        }
  
        const secondData = await secondResponse.json(); 
  
        //console.log('[Api 2] -> verificare licenta:', secondData);
  
        if (secondData.data.ok2go === true) {
          setLicenseMessage(`<h5 class="text-success"><br/>${secondData.data.message.substring(0, secondData.data.message.indexOf('[ok2go]'))}</h5>`);
          sessionStorage.setItem('ok2go', true);
          localStorage.setItem('ok2go', true);
          document.cookie = `ok2go=true; path=/; max-age=86400;`;
          setButonActivare(false);
          //console.log(secondData.data.message);
  
          setTimeout(() => {
            navigate('/panou');
          }, 2000);
        } else {
          sessionStorage.setItem('ok2go', false);
          localStorage.setItem('ok2go', false);
          document.cookie = `ok2go=false; path=/; max-age=86400;`;
          setLicenseMessage(`<h5 class="text-danger"><br/>Licența nu este validă. <br>(${secondData.data.message})</h5>`);
          setButonActivare(true);
          //console.error('Licența nu este validă.');
        }
      }
    } catch (error) {
      setError(`A apărut o eroare: ${error.message}`);
    } finally { 
    }
  };

  useEffect(() => {
    if (sessionStorage.getItem('name') === null) {
      navigate('/logout'); 
    } else { 
      setUsername(sessionStorage.getItem('name'));
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (licenseMessage !== '') {
      $('.verificareLicenta').hide();
    } else {
      $('.verificareLicenta').show();
    }
  }, [licenseMessage]);
 

  //aduc hook-ul de drepturi
  const { areDrept } = DrepturiUtilizator();
  
  const AccesLaToataAplicatia = 'AccesLaToataAplicatia';
  return (
    <div>
      {error && <div className="text-danger">{error}</div>}
      {error && <div>Error: {error.message}</div>}
      {licenseData.licenta && (
        <div className="text-center card container mx-auto p-5 mt-5">
          <h6>Versiune aplicație: {licenseData.licenta.appversion} <br /><br />Utilizator: {username} <br /><br />Licență: {licenseData.licenta.serialno}</h6>
          <div className="verificareLicenta"><br /><i className="fa fa-refresh fa-2x fa-spin"></i><br/>Se verifică</div>
         {licenseMessage && <div dangerouslySetInnerHTML={{ __html: licenseMessage }} />}
         {butonActivare && (
            <>
              <br></br>
              {
                areDrept(AccesLaToataAplicatia) ? (
                  <>
                    <Button variant="contained" color="success" size="small" onClick={handleOpenDialogLicenta}>
                      Modificare licență
                    </Button>
                    <SchimbareLicenta
                      open={openDialogLicenta}
                      handleClose={handleCloseDialogLicenta}
                    />
                  </>
                ) : (
                  <Alert severity='warning'>Licența nu este validă. Vă rugăm să contactați administratorul aplicației pentru asistență în remedierea acestei probleme.</Alert>
                )
              }

            </>
          )}
         {/*<p>Status ID: {licenseData.licenta.id}</p>*/}
          {/*<p>Companie: {licenseData.licenta.company}</p>*/}
          {/*<p>Detinator: {licenseData.licenta.owner}</p>*/} 
          {/*<p>Data inregistrare: {licenseData.licenta.regdate}</p>*/}
          {/*<p>Versiune db: {licenseData.licenta.dbversion}</p>*/}
          {/*<p>Versiune app: {licenseData.licenta.appversion}</p>*/}
          {/*<p>Stocare limita (MB): {licenseData.licenta.storagequotaMB}</p>*/}
          {/*<p>CODB: {licenseData.codb}</p>*/}
          </div>
      )}
      
    </div>
  );

};
