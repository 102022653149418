import React, { useState } from 'react';
import { Box, Typography, Button } from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import ReplayIcon from '@mui/icons-material/Replay';

const FileUpload = ({ onFileUpload }) => {
  const [fileUploaded, setFileUploaded] = useState(false);
  const [fileName, setFileName] = useState('');

  const handleFileChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      setFileUploaded(true);
      setFileName(file.name);
      onFileUpload(file);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    if (event.dataTransfer.files && event.dataTransfer.files[0]) {
      const file = event.dataTransfer.files[0];
      setFileUploaded(true);
      setFileName(file.name);
      onFileUpload(file);
    }
  };

  return (
    <Box
      sx={{
        border: '2px dashed',
        borderColor: fileUploaded ? 'success.main' : 'grey.400',
        borderRadius: 2,
        p: 3,
        textAlign: 'center',
        backgroundColor: fileUploaded ? 'success.light' : 'background.paper',
        cursor: 'pointer',
        transition: 'background-color 0.3s, border-color 0.3s',
      }}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      <Typography variant="h6" gutterBottom>
        {fileUploaded ? `Fișier încărcat: ${fileName}` : 'Trageți fișierul aici sau faceți clic pentru a încărca'}
      </Typography>
      <Button
        variant="contained"
        component="label"
        startIcon={fileUploaded ? <ReplayIcon /> : <UploadFileIcon />}
        sx={{ mt: 2 }}
      >
        {fileUploaded ? 'Înlocuiți fișierul' : 'Selectați fișierul'}
        <input
          type="file"
          accept=".xlsx, .xls"
          hidden
          onChange={handleFileChange}
        />
      </Button>
    </Box>
  );
};

export default FileUpload;
