import React, { useState, useEffect, useCallback } from 'react';

import verificareToken from './VerificareToken';

import {
  Dialog, DialogTitle, DialogContent, Backdrop, Tooltip, DialogActions, Button, FormControl, InputLabel, TextareaAutosize, Select, TextField, MenuItem, CircularProgress, Grid, Typography,FormControlLabel, Switch, Box } from '@mui/material';
import Swal from 'sweetalert'; 
import Autocomplete from '@mui/material/Autocomplete';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, IconButton  } from '@mui/material';
import { useParams } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { ro } from 'date-fns/locale';

import AddIcon from '@mui/icons-material/Add';

function CreareDosar({ open, onClose }) {

const { token } = verificareToken();
  
const [adminModalOpen, setAdminModalOpen] = useState(false);
const [obiectNou, setObiectNou] = useState(false);
const [currentAdminObiect, setCurrentAdminObiect] = useState(null);
const [textareaValue, setTextareaValue] = useState("");
const [tipDosar, setTipDosar] = React.useState('instanta');

const handleChangeTipDosar = (
  event: React.MouseEvent<HTMLElement>,
  newTipDosar: string | null,
) => {
  if (newTipDosar !== null) {
    setTipDosar(newTipDosar);
  }

  if (newTipDosar === 'consultanta') {
    Swal(
      `Dosar de ${window.dencons || 'consultanță'}`,
      `Urmează să creați un dosar de ${window.dencons || 'consultanță'}, iar acesta conține doar informații specifice activității de ${window.dencons || 'consultanță'}.`,
      'warning'
    );
  }
};
const [addLoading, setAddLoading] = useState(false);
const [idstare, setIdstare] = useState('');
const [stari, setStari] = useState([]);
const [loadingStari, setLoadingStari] = useState(true);
const [loadingInfo, setLoadingInfo] = useState(false);
const [isCreatingDosar, setIsCreatingDosar] = useState(false);

//anulez loadingul cand redeschid crearea dosarului
useEffect(() => {
  if (open && loadingInfo) {
    setLoadingInfo(false);
  }
}, [open]);

  const [clienti, setClienti] = useState([]); 
  const [clientSelectat, setClientSelectat] = useState(''); 

  const [numarIntern, setNumarIntern] = useState('');
  const [numarDosar, setNumarDosar] = useState('');
  const [anDosar, setAnDosar] = useState('');
  const [instante, setInstante] = useState([]);
  const [materii, setMaterii] = useState([]);
  const [obiecte, setObiecte] = useState([]);
  const [loadingObiecte, setLoadingObiecte] = useState(false);
  const [idInstanta, setIdInstanta] = useState('');
  const [idMaterie, setIdMaterie] = useState('');
  const [idObiect, setIdObiect] = useState('');
  const [accesoriu, setAccesoriu] = useState('');
  const [anError, setAnError] = useState('');
  const [numarAutomat, setNumarAutomat] = useState(false);
  //const [dataCreare, setDataCreare] = useState(new Date().toISOString().split('T')[0]);
  const [dataCreare, setDataCreare] = useState(new Date());

  const [debitRecuperat, setDebitRecuperat] = useState('');
  const [debitDosar, setDebitDosar] = useState('');
  const [observatii, setObservatii] = useState('');
  const [isFinalizat, setIsFinalizat] = useState(false);
  
  const [openDialog, setOpenDialog] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [stareToEdit, setStareToEdit] = useState(null);
  const [newDenumire, setNewDenumire] = useState('');
  const [newDenumireEdit, setNewDenumireEdit] = useState('');
  const [error, setError] = useState('');

  const [dataFinalizare, setDataFinalizare] = useState('');
  const [solutie, setSolutie] = useState('');
  const [descriereSolutie, setDescriereSolutie] = useState('');
  
  const [instantaInput, setInstantaInput] = useState('');
  const [tipParte, setTipParte] = useState('1');
  const [dateParte, setDateParte] = useState({ 
    societate: '',
    cui: '',
    cnp: '',
    adresa: '',
    banca: '',
    iban: '',
    telefon: '',
    email: '',
    numeprenume: '',
    codabonat: '',
    registrucomert: '' 
  });

  const handleChange = (e) => {
    setTipParte(e.target.value);
  };
  const handleChangeStare = (event) => {
    setIdstare(event.target.value);
  };
  const [cnpError, setCnpError] = useState('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
  
    if (name === 'cnp') {
      if (value && (value.length !== 13 || !/^\d+$/.test(value))) {
        setCnpError('CNP-ul trebuie să conțină exact 13 cifre.');
      } else {
        setCnpError('');
      }
    }
  
    setDateParte(prevDateParte => ({
      ...prevDateParte,
      [name]: value
    }));
  };
  
  

  const handleSaveAdminObiect = async () => {
    const apiUrl = currentAdminObiect ? `${window.API_URL}obiecte/${currentAdminObiect.id}` : `${window.API_URL}obiecte`;
    const requestOptions = {
      method: currentAdminObiect ? 'PATCH' : 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
    };
  
    try {
        const response = await fetch(`${apiUrl}?denumire=${textareaValue}&idMaterie=${idMaterie}`, requestOptions);
        if (response.ok) {
        const data = await response.json();
        setObiectNou(true); 
      } else {
        console.error('A apărut o eroare la salvarea datelor');
      }
    } catch (error) {
      console.error('A apărut o eroare la salvarea datelor', error);
    } finally {
      handleAdminModalClose();
    }
  };

  

  
  const resetFields = () => {
    setInstantaInput('');  
    setIdInstanta('');    
    setNumarAutomat(false); 
    setNumarIntern('');    
    setNumarDosar('');    
    setAnDosar(''); 
    setIdObiect('');    
    setIdMaterie('');    
    setAccesoriu('');   
    setDateParte({ 
      societate: '',
      cui: '',
      cnp: '',
      adresa: '',
      banca: '',
      iban: '',
      telefon: '',
      email: '',
      numeprenume: '',
      codabonat: '',
      registrucomert: '' 
    });
    setTipParte('1');
    setDebitRecuperat('');
    setDebitDosar('');
    setObservatii('');
    setIdstare('');
    setDataFinalizare('');
    setSolutie('');
    setDescriereSolutie('');

  };
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};


  const handleSolutieChange = (event) => {
    setSolutie(event.target.value);
  };

  const handleOpenDialog = (stare = null) => {
    setOpenDialog(true);
    setEditMode(!!stare);
    setNewDenumire(stare ? stare.denumire : '');
  };

  const handleDenumireChange = useCallback((event) => {
    setNewDenumireEdit(event.target.value);
  }, []);
  
  const handleEditare = (stare = null) => {
    setEditMode(!!stare);
    setStareToEdit(stare);
    setNewDenumireEdit(stare ? stare.denumire : '');
  };

  const handleCancelEdit = () => {
    setEditMode(false);
    setStareToEdit(null);
    setNewDenumireEdit('');
    setError('');
  };
 
  const handleCloseDialog = () => {
    setOpenDialog(false);
    setEditMode(false);
    setStareToEdit(null);
    setError('');
  };

  const handleAddStare = async () => {
    if (!newDenumire.trim()) {
      setError('Denumirea nu poate fi goală.');
      return;
    }
    setAddLoading(true);
    try {
      const response = await fetch(`${window.API_URL}addStare?denumire=${newDenumire}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
      const {data} = await response.json();
      if (response.ok) {
        setStari([...stari, { id: data.stare.id, denumire: data.stare.denumire}]); 
        setNewDenumire('');  
      } else {
        setError(data.message || 'A apărut o eroare la adăugarea stării.');
      }
    } catch (error) {
      console.error('Eroare:', error);
      setError('A apărut o eroare la adăugarea stării.');
    }
    setAddLoading(false);
  };

  const handleAddOrUpdateStare = async () => {
    if (!newDenumireEdit.trim()) {
      setError('Denumirea nu poate fi goală.');
      return;
    }
    const url = editMode ? `updateStare/${stareToEdit.id}` : 'addStare';
    const method = editMode ? 'PATCH' : 'POST';
    try {
      const response = await fetch(`${window.API_URL}${url}?denumire=${newDenumireEdit}`, {
        method: method,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
      if (response.ok) {
        Swal('Succes', `Starea a fost ${editMode ? 'actualizată' : 'adăugată'} cu succes!`, 'success');
        fetchStari(); 
        handleCancelEdit();
      } else {
        setError('A apărut o eroare la actualizarea stării.');
      }
    } catch (error) {
      console.error('Eroare:', error);
      setError('A apărut o eroare la actualizarea stării.');
    }
  };

  const handleDeleteStare = (userId) => {
    Swal({
      title: 'Sigur doriți ștergerea aceste stări?',
      text: 'Această acțiune nu poate fi anulată!',
      icon: 'warning',
      buttons: ['Anulează', 'Da, șterge!'],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        handleDeleteStareOK(userId);
      }
    });
  };
  
  
  const handleDeleteStareOK = async (id) => {
    try {
      const response = await fetch(`${window.API_URL}deleteStare/${id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
      if (response.ok) {
        Swal('Succes', 'Starea a fost ștearsă cu succes!', 'success');
        fetchStari();  
      } else {
        setError('A apărut o eroare la ștergerea stării.');
      }
    } catch (error) {
      console.error('Eroare:', error);
      setError('A apărut o eroare la ștergerea stării.');
    }
  };

    const fetchStari = async () => {
      try {
        const response = await fetch(`${window.API_URL}stari`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });
        const data = await response.json();
        setStari(data.data.stari);
        setLoadingStari(false);
      } catch (error) {
        console.error('Error fetching stari:', error);
      }
    };

  useEffect(() => {
    fetchStari();
  }, [token]);
    // preiau lista clientilor
    useEffect(() => {
      fetch(`${window.API_URL}clienti`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then(response => response.json())
        .then(data => setClienti(data.data.clienti))
        .catch(error => console.error('Error:', error));
    }, [token]); // Dependență: token
  
    // schimbarea clientului
    const handleSelectClient = (event) => {
      setClientSelectat(clientSelectat);
  
      // preiau datele clientului schimbat
      if (clientSelectat) {
        fetch(`${window.API_URL}client/${clientSelectat}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(response => response.json())
        .then(data => setDateParte(data.data.client))  
        .catch(error => console.error('Error:', error));
      }
    };


    useEffect(() => {
      if (idstare && stari.find(stare => stare.id === idstare)?.denumire.toLowerCase().includes('finalizat')) {
          setDataFinalizare(new Date());
      }
  }, [idstare, stari]);
  
  
  useEffect(() => {
    const stareSelectata = stari.find(stare => stare.id === idstare);
    if (stareSelectata && stareSelectata.denumire.toLowerCase().includes('finalizat')) {
        setIsFinalizat(true);
    } else {
        setIsFinalizat(false);
        setDataFinalizare(null);
        setSolutie('');
        setDescriereSolutie('');
    }
}, [idstare, stari]);


  useEffect(() => {
    if (idMaterie) {
      setLoadingObiecte(true); 
      // incarc obiectele in functie de ce materie am selectat
      fetch(`${window.API_URL}obiecte/materie/${idMaterie}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then(response => response.json())
        .then(data => {
          setObiecte(data.data.obiecte);
          setLoadingObiecte(false);  
        })
        .catch(error => {
          console.error('Error:', error);
          setLoadingObiecte(false);  
        });
    }
  }, [idMaterie, token, obiectNou]);
  
  useEffect(() => {
    // incarc instantele din api
    fetch(`${window.API_URL}instante`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(response => response.json())
      .then(data => setInstante(data.data.instante))
      .catch(error => console.error('Error:', error));

    // incarc materiile din api
    fetch(`${window.API_URL}materii`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(response => response.json())
      .then(data => setMaterii(data.data.materii))
      .catch(error => console.error('Error:', error));
  }, [token]);

  useEffect(() => {
    if (idMaterie) {
      // incarc obiecte in functie de materie id
      fetch(`${window.API_URL}obiecte/materie/${idMaterie}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then(response => response.json())
        .then(data => setObiecte(data.data.obiecte))
        .catch(error => console.error('Error:', error));
    }
  }, [idMaterie, token]);

const [errors, setErrors] = useState({
  numarIntern: false,
  numarDosar: false,
  anDosar: false,
  idInstanta: false,
  idMaterie: false,
  idObiect: false,
  numeprenume: false,
  societate: false,
  codabonat: false,
});


const validateForm = () => {
  let newErrors = {
    numarIntern: !numarIntern && !numarDosar && !(numarAutomat && tipDosar === 'consultanta'),
    // Inițializează erorile cu valori false pentru a evita erori undefined la evaluare
    numarDosar: false,
    anDosar: false,
    idInstanta: false,
    idMaterie: !idMaterie,
    idObiect: !idObiect,
    numeprenume: false,
    societate: false,
    codabonat: false,
  };

  // Condiții specifice pentru instanță
  if (tipDosar === 'instanta') {
    Object.assign(newErrors, {
      numarDosar: !numarDosar && !numarAutomat,
      anDosar: !anDosar,
      idInstanta: !idInstanta,
    });
  }

  // Adaugă condiții în funcție de tipParte
  switch (tipParte) {
    case '1': // Persoană fizică
      newErrors.numeprenume = !dateParte.numeprenume;
      break;
    case '2': // Persoană juridică
    case '4': // Societate de asigurări
    case '5': // Externă
      newErrors.societate = !dateParte.societate;
      // Adaugă aici alte condiții necesare pentru persoană juridică, dacă este cazul
      break;
    case '3': // Abonat
      newErrors.codabonat = !dateParte.codabonat;
      break;
    default:
      // Dacă este necesar, gestionează cazul default
      break;
  }

  setErrors(newErrors);

  // Verifică dacă există vreo eroare
  return !Object.values(newErrors).some(error => error);
};

  const handleCreareDosar = async () => {
    if (!validateForm()) {
      Swal('Atenționare', 'Vă rugăm să completați toate câmpurile obligatorii.', 'warning');
      return;
    }
  
        if (tipDosar === 'instanta') {
          if (!numarDosar && (!numarIntern && !numarAutomat)) {
              Swal({
                  title: 'Atenționare',
                  text: 'Vă rugăm să completați fie Număr dosar, fie Număr intern sau alegeți alocare automată.',
                  icon: 'warning',
                  button: 'OK',
              });
              return;
        }
      } else {
        if (!numarIntern && !numarAutomat) {
          Swal({
              title: 'Atenționare',
              text: 'Vă rugăm să completați fie Număr intern sau alegeți alocare automată.',
              icon: 'warning',
              button: 'OK',
          });
          return;
      } 
    }

    if (tipDosar === 'instanta') {
        if ((!idMaterie || !idObiect || !idInstanta)) {
            let errorMessage = 'Vă rugăm să completați următoarele câmpuri: ';
              
            if (!idMaterie) errorMessage += 'Materie, ';
            if (!idObiect) errorMessage += 'Obiect, ';
            
            errorMessage = errorMessage.slice(0, -2);
            
            Swal({
                title: 'Atenționare',
                text: errorMessage,
                icon: 'warning',
                button: 'OK',
            });
            return;
        }
      
      {/*
        if (!/^[12]\d{3}$/.test(anDosar)) {
          Swal({
              title: 'Atenționare',
              text: 'Anul dosarului trebuie să fie corect.',
              icon: 'warning',
              button: 'OK',
          });
          return;
          }
        */}
      
      } else {

      }
    
    

      setLoadingInfo(true);
    try {
      let idtipdosar;
      if (tipDosar === 'instanta') {
        idtipdosar = 1
      } else {
        idtipdosar = 0
      }
      
let numarInternFinal;
if (numarAutomat) {
  numarInternFinal = null; 
} else {
  numarInternFinal = `${numarIntern}/${anulDinDataCreare}`;
  //numarIntern: numarAutomat ? null : numarIntern,
}

let formattedDataCreare = null;
if (dataCreare) {
    formattedDataCreare = formatDateForSubmission(dataCreare);
}

        const data = {
            is_dosar_instanta: idtipdosar,
            numarAutomat: numarAutomat ? 1 : 0,
            ...(numarIntern && { numarIntern: numarInternFinal }),
            idObiect: idObiect,
            idMaterie: idMaterie,
            dataCreare: formattedDataCreare,
            valoaredosar: debitDosar,
            valoarerecuperata: debitRecuperat,
            comentarii: observatii,
            idtipparte: tipParte,
            numeprenume: dateParte.numeprenume,
            societate: dateParte.societate,
            cnp: dateParte.cnp,
            cui: dateParte.cui,
            codabonat: dateParte.codabonat,
            registrucomert: dateParte.registrucomert,
            adresa: dateParte.adresa,
            telefon: dateParte.telefon,
            email: dateParte.email,
            banca: dateParte.banca,
            iban: dateParte.iban,
            
            numarDosar: numarDosar,
            instantaDosar: instantaInput,
            idinstantaDosar: idInstanta,
            anDosar: anDosar,
            accesoriu: accesoriu,
           
            idStare: idstare,
            dataFinalizare: formatDateForSubmission(dataFinalizare),
            solutie: solutie,
            descriereSolutie: descriereSolutie, 
        };
 
        if (numarAutomat) {
            data.automat = 1;  //adaug parametrul automat = 1 doar daca switch-ul este activ
        }
      const response = await fetch(`${window.API_URL}dosare`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });
  
      const responseData = await response.json();
  
      if (response.status === 200) {
        // intreb userul ce sa faca
        Swal({
          title: 'Succes',
          text: 'Dosarul a fost creat cu succes!',
          icon: 'success',
          buttons: ['Continuați editarea', 'Reveniți la lista de dosare'],
        }).then((value) => {
          if (value) {
            resetFields();
            onClose();
          } else {
            // Redirect la pagina de editare dosar
            const dosarId = responseData.data.dosar.id;
            window.location.href = `/editare-dosar/${dosarId}`;
          }
        });
      } else {
        let errorMessage = ' ';
        if (responseData.message && typeof responseData.message === 'object') {
          Object.values(responseData.message).forEach((msgArray) => {
            msgArray.forEach((msg) => {
              errorMessage += `${msg} `;
            });
          });
        } else if (typeof responseData.message === 'string') {
          errorMessage += responseData.message;
        }
    
        setLoadingInfo(false);
        Swal('Atenționare', errorMessage, 'warning');
      }
    } catch (error) {
      console.error('Error:', error);
      setLoadingInfo(false);
      Swal('Atenționare', 'A apărut o eroare la crearea dosarului.', 'warning');
    }
  };
  
  const formatDateForSubmission = (date) => {
    if (!date) return null;
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
};


const anulDinDataCreare = dataCreare ? dataCreare.getFullYear() : '';


const handleAdminModalOpen = (obiect) => {
  if (obiect) {
    setCurrentAdminObiect(obiect);
    setTextareaValue(obiect.denumire || "");
  } else {
    setCurrentAdminObiect(null);
    setTextareaValue("");
  }
  setAdminModalOpen(true);
};

const handleAdminModalClose = () => {
  setCurrentAdminObiect(null);
  setTextareaValue("");
  setAdminModalOpen(false);
};

const handleTextareaChange = (e) => {
  setTextareaValue(e.target.value);
};


  return (
    <Dialog
        open={open} 
        onClose={(event, reason) => {
          resetFields();
          if (reason !== 'backdropClick') {
            onClose();
          }
        }}
        disableEscapeKeyDown
        maxWidth="lg" fullWidth
        className='comprimare'
    >
      <DialogTitle className='titluModal' style={{display:'flex'}}>
          Creare dosar 
          <div className='butoaneTipDosar'>
          <ToggleButtonGroup
              value={tipDosar}
              exclusive
              onChange={handleChangeTipDosar} 
              size='small'
              style={{marginLeft:'20px'}}
              color="warning"
            >
              <ToggleButton value="instanta">
                Instanță
              </ToggleButton>
              <ToggleButton value="consultanta">
                {window.dencons ? window.dencons.charAt(0).toUpperCase() + window.dencons.slice(1) : 'Consultanță'} 
              </ToggleButton>

          </ToggleButtonGroup>
          </div>
      </DialogTitle>
      <DialogContent className='mt-3'>
        <Grid container spacing={2}className="pt-3"> 
            <Grid item xs={4}>
              <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ro}>
                  <DatePicker
                      label="Dată creare dosar"
                      value={dataCreare}
                      onChange={(newValue) => {
                          if (!newValue) {
                              setDataCreare(null);
                          } else {
                              setDataCreare(new Date(newValue));
                          }
                      }}
                      renderInput={(params) => <TextField {...params} fullWidth margin="dense" size="small" />}
                      inputFormat="dd.MM.yyyy"
                      className='inputMic mt-2'
                  />
              </LocalizationProvider>
            </Grid>


            <Grid item xs={4} container alignItems="flex-end" style={{marginTop:'-5px'}}>
              <Grid item xs>
                <TextField
                  error={errors.numarIntern}
                  helperText={errors.numarIntern ? "Acest câmp este obligatoriu" : ""}
                  autoFocus
                  margin="dense"
                  label="Număr intern"
                  type="text"
                  fullWidth
                  value={numarIntern}
                  onChange={(e) => {
                    setNumarIntern(e.target.value);
                    setErrors(prev => ({...prev, numarIntern: false}));  
                  }}
                  disabled={numarAutomat}
                  size="small"
                />
              </Grid>
              <Grid item>
              <Typography variant="subtitle1" style={{paddingBottom: '8px', marginLeft: '4px'}}>
                /{anulDinDataCreare}
              </Typography>
              </Grid>
            </Grid>

            <Grid item xs={3} style={{}}>
                <FormControlLabel className='mt-3 text-center' style={{fontSize: '10px'}}
                    control={<Switch checked={numarAutomat} onChange={() => setNumarAutomat(!numarAutomat)} />}
                    label="Număr automat"
                />
          </Grid>
          {tipDosar === 'instanta' && ( 
          <>
          <Grid item xs={4}>
                <TextField
                    error={errors.numarDosar}
                    helperText={errors.numarDosar ? "Acest câmp este obligatoriu" : ""}
                    autoFocus
                    margin="dense"
                    label="Număr dosar"
                    type="number"
                    fullWidth
                    value={numarDosar}
                    size="small"
                    onChange={(e) => {
                      setNumarDosar(e.target.value);
                      setErrors(prev => ({...prev, numarDosar: false, numarIntern: false}));  
                    }}
                />
            </Grid>
            <Grid item xs={4}>
            <TextField
                autoFocus
                margin="dense"
                label="Cod Instanță"
                type="number"
                fullWidth
                value={instantaInput}
                size="small"
                onChange={(e) => {
                const inputCod = e.target.value;
                setInstantaInput(inputCod); //setez instanta si apoi o caut dupa cod
                const instantaCautata = instante.find((instanta) => instanta.cod === inputCod.toString());
                
                if (instantaCautata) {
                    setIdInstanta(instantaCautata.id); //setez id instanta daca am
                } else {
                    setIdInstanta(null);
                }
                }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
                error={errors.anDosar} // errors.anDosar || Boolean(anError)
                helperText={errors.anDosar ? "Acest câmp este obligatoriu" : anError}
                margin="dense"
                label="An dosar"
                type="text" 
                fullWidth
                value={anDosar}
                size="small"
                onChange={(e) => {
                const inputAn = e.target.value;

                setErrors(prev => ({...prev, anDosar: false})); 
                // verificare format an
                {/*
              if (!/^[12]?$|^[12]\d{0,3}$/.test(inputAn)) {
                    setAnError('Anul dosarului trebuie să fie corect.');
                } else {
                    setAnError('');
                }

              */}
                setAnDosar(inputAn);
                }}
            />
          </Grid>    
          <Grid item xs={6} style={{marginTop:'-7px'}}>
            <TextField
              margin="dense"
              label="Accesoriu"
              type="text"
              fullWidth
              value={accesoriu}
              onChange={(e) => setAccesoriu(e.target.value)}
              size="small"
            />
          </Grid> 
          <Grid item xs={6}>
            <FormControl fullWidth>
                <InputLabel shrink={Boolean(instantaInput)}>Instanța</InputLabel>
                <Autocomplete
                options={instante}
                size="small"
                getOptionLabel={(instanta) => instanta.denumire}
                value={instante.find((instanta) => instanta.id === idInstanta) || null}
                onChange={(_, newValue) => {
                    setIdInstanta(newValue?.id || null);
                    setInstantaInput(newValue?.cod || ''); // daca primesc cod din campul de mai sus setez in dropdown instanta curenta
                    setErrors(prev => ({...prev, idInstanta: false}));
                }}
                renderInput={(params) => <TextField {...params}  
                onChange={() => setErrors(prev => ({...prev, idInstanta: false}))}
                error={errors.idInstanta}
                helperText={errors.idInstanta ? "Vă rugăm să selectați o instanță" : ""} />}
                />
            </FormControl>
            </Grid>
            
          </>
          )}  
          <Grid item xs={6}>
          <Typography>Materie dosar</Typography>
            <FormControl fullWidth>
              <Autocomplete
                    options={materii}
                    getOptionLabel={(materie) => materie.denumire}
                    size="small"
                    value={materii.find((materie) => materie.id === idMaterie) || null} 
                    onChange={(_, newValue) => {
                      setIdMaterie(newValue?.id)
                      setErrors(prev => ({...prev, idMaterie: false}));  
                    }}
                    renderInput={(params) => (
                        <TextField
                        {...params}
                        onChange={() => setErrors(prev => ({...prev, idMaterie: false}))}
                        placeholder="Alegeti o materie"
                        InputLabelProps={{ shrink: Boolean(idMaterie) }}
                        error={errors.idMaterie}
                        helperText={errors.idMaterie ? "Vă rugăm să selectați o materie" : ""}
                        required
                        />
                    )}
                />
            </FormControl>
          </Grid> 
          <Grid item xs={5}>
            <Typography>Obiect dosar</Typography>
            <FormControl fullWidth>
              {loadingObiecte ? (
                <CircularProgress size={20} style={{ marginLeft: '10px' }} />
              ) : (
                <Autocomplete
                    options={obiecte}
                    size="small"
                    getOptionLabel={(obiect) => obiect.denumire}
                    value={obiecte.find((obiect) => obiect.id === idObiect) || null}
                    onChange={(_, newValue) => {
                      setIdObiect(newValue?.id);
                      setErrors(prev => ({...prev, idObiect: false}));  
                    }}
                    disabled={!idMaterie}
                    renderInput={(params) => (
                        <TextField
                        {...params}
                        onChange={() => setErrors(prev => ({...prev, idObiect: false}))}
                        placeholder={idMaterie ? "Alegeți un obiect" : "Alegeți mai întâi o materie"}
                        InputLabelProps={{ shrink: Boolean(idObiect) }}
                        error={errors.idObiect}
                        helperText={errors.idObiect ? "Vă rugăm să selectați un obiect" : ""}
                        required
                        />
                    )}
                />

              )}
            </FormControl>
          </Grid>
          <Grid item xs={1}>
          <Tooltip title="Adăugare obiect nou pe materie">
          <IconButton style={{marginTop:'25px'}}
              onClick={() => handleAdminModalOpen(null)}
              variant="contained"
              size="small"
              color="secondary"
              startIcon={<AddIcon />} 
              disabled={
                (loadingObiecte || (Array.isArray(obiecte) && obiecte.length === 0)) &&
                !idMaterie 
              }
              
            >
              <AddIcon />
            </IconButton>
          </Tooltip>
          </Grid>
          <>
          <Grid item xs={12} className="separator">
              Date client 
          </Grid>
          <Grid item xs={12}> 
          <Autocomplete
          style={{marginTop:'5px', marginBottom:'6px'}}
              value={clientSelectat}
              onChange={(event, newValue) => {
                if (newValue) {
                  setClientSelectat(newValue); // Setează noul client selectat
                  fetch(`${window.API_URL}client/${newValue.idclient}`, {
                    method: 'GET',
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  })
                  .then(response => response.json())
                  .then(data => {
                    setTipParte('1');  
                    setDateParte(data.data.client);
                    setTipParte(data.data.client.idtipparte.toString());
                  })
                  .catch(error => console.error('Error:', error));
                } else {
                  setClientSelectat(null); // Resetare dacă nu este selectat niciun client
                }
              }}
              options={clienti}
              getOptionLabel={(option) => option.client || ''}
              renderInput={(params) => (
                <TextField {...params} label="Selectează Client" variant="outlined" size="small" />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth key={`tip-parte-${tipParte}`}>
              <InputLabel id="tipParte-label">Tip parte</InputLabel>
              <Select
                labelId="tipParte-label"
                id="tipParte-select"
                value={tipParte}
                label="Tip parte"
                onChange={handleChange}
                size="small"
              >
                <MenuItem value="1">Persoană fizică</MenuItem>
                <MenuItem value="2">Persoană juridică</MenuItem>
                <MenuItem value="3">Abonat</MenuItem>
                <MenuItem value="4">Societate de asigurări</MenuItem>
                <MenuItem value="5">Externă</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {/* Câmpuri pentru Persoană Fizică */}
          {tipParte === '1' && (
            <>
              <Grid item xs={6} style={{marginTop: '-7px'}}>
                <TextField
                  margin="dense"
                  label="Nume și prenume"
                  name="numeprenume"
                  fullWidth
                  value={dateParte.numeprenume} 
                  onChange={(e) => { 
                    handleInputChange(e);
                    setErrors(prev => ({...prev, numeprenume: false})); 
                  }}
                  size="small"
                  error={errors.numeprenume}
                  helperText={errors.numeprenume ? "Vă rugăm să selectați un client existent sau creați un client nou" : ""}
                />
              </Grid>
              <Grid item xs={4}>
              <TextField
                margin="dense"
                label="CNP"
                name="cnp"
                fullWidth
                value={dateParte.cnp}
                onChange={handleInputChange}
                size="small" 
                error={!!cnpError}  
                helperText={cnpError}  
              />
              </Grid>
            </>
          )}

          {/* Câmpuri pentru Persoană Juridică, Societate de Asigurări și Externă */}
          {(tipParte === '2' || tipParte === '4' || tipParte === '5') && (
            <>
              <Grid item xs={6} style={{marginTop: '-7px'}}>
                <TextField
                  margin="dense"
                  label="Reprezentant legal"
                  name="numeprenume"
                  fullWidth
                  value={dateParte.numeprenume}
                  onChange={(e) => {
                    handleInputChange(e); 
                  }}
                  size="small"
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  margin="dense"
                  label="Societate"
                  name="societate"
                  fullWidth
                  value={dateParte.societate}
                  onChange={(e) => {
                    handleInputChange(e);
                    setErrors(prev => ({...prev, societate: false})); 
                  }}
                  size="small"
                  error={errors.societate}
                  helperText={errors.societate ? "Vă rugăm să selectați un client existent sau creați un client nou" : ""}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  margin="dense"
                  label="CUI"
                  name="cui"
                  fullWidth
                  value={dateParte.cui}
                  onChange={(e) => {
                    handleInputChange(e); 
                  }}
                  size="small"
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  margin="dense"
                  label="Reg. Com."
                  name="registrucomert"
                  fullWidth
                  value={dateParte.registrucomert}
                  onChange={(e) => {
                    handleInputChange(e); 
                  }}
                  size="small"
                />
              </Grid>
            </>
          )}

          {/* Câmpuri pentru Abonat */}
          {tipParte === '3' && (
            <Grid item xs={6} style={{marginTop: '-7px'}}>
              <TextField
                margin="dense"
                label="Cod abonat"
                name="codabonat"
                fullWidth
                value={dateParte.codabonat}
                onChange={(e) => {
                  handleInputChange(e);
                  setErrors(prev => ({...prev, codabonat: false})); 
                }}
                size="small"
                error={errors.codabonat}
                helperText={errors.codabonat ? "Vă rugăm să selectați un client existent sau creați un client nou" : ""}
              />
            </Grid>
          )}

          {/* Câmpuri comune pentru toate tipurile de parte */}
          <Grid item xs={4}>
            <TextField
              margin="dense"
              label="Adresa"
              name="adresa"
              fullWidth
              value={dateParte.adresa}
              onChange={(e) => {
                handleInputChange(e); 
              }}
              size="small"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              margin="dense"
              label="Banca"
              name="banca"
              fullWidth
              value={dateParte.banca}
              onChange={(e) => {
                handleInputChange(e); 
              }}
              size="small"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              margin="dense"
              label="Cod IBAN"
              name="iban"
              fullWidth
              value={dateParte.iban}
              onChange={(e) => {
                handleInputChange(e); 
              }}
              size="small"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              margin="dense"
              label="Telefon"
              name="telefon"
              fullWidth
              value={dateParte.telefon}
              onChange={(e) => {
                handleInputChange(e); 
              }}
              size="small"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              margin="dense"
              label="Email"
              name="email"
              fullWidth
              value={dateParte.email}
              onChange={(e) => {
                handleInputChange(e); 
              }}
              size="small"
            />
          </Grid>
          </>

          
          <Grid item xs={12} className="separator">
              Debit dosar
          </Grid>
          <Grid item xs={6}>
            <TextField
              margin="dense"
              label="Debit dosar"
              type="text"
              fullWidth
              value={debitDosar}
              size="small"
              onChange={(e) => setDebitDosar(e.target.value)}
            />
          </Grid> 
          <Grid item xs={6}>
            <TextField
              margin="dense"
              label="Debit recuperat"
              type="text"
              fullWidth
              value={debitRecuperat}
              size="small"
              onChange={(e) => setDebitRecuperat(e.target.value)}
            />
          </Grid> 

          <Grid item xs={12}>
            <TextField
              margin="dense"
              label="Observații"
              multiline 
              rows={2}
              fullWidth
              value={observatii}
              size="small"
              onChange={(e) => setObservatii(e.target.value)}
            />
          </Grid>

      <Grid item xs={12} className="separator">
          Stare dosar 
      </Grid>
        <Grid item xs={10}>
            <FormControl fullWidth variant="outlined" style={{marginTop:'10px'}}>
                <InputLabel htmlFor="stare-dosar">Stare dosar</InputLabel>
                <Select
                label="Stare dosar"
                value={idstare}
                onChange={handleChangeStare}
                inputProps={{
                    name: 'stare-dosar',
                    id: 'stare-dosar',
                }}
                size='small'
                >
                <MenuItem key={0} value={0} disabled>
                  Alegeți o stare
                </MenuItem>
                {stari.map((stare) => (
                    <MenuItem key={stare.id} value={stare.id}>
                        {capitalizeFirstLetter(stare.denumire)}
                    </MenuItem>
                ))}
                </Select>
            </FormControl>
        </Grid>
        <Grid item xs={2}>
            <Button size="large"  onClick={() => handleOpenDialog()} style={{marginTop:'7px'}} fullWidth variant="outlined" color="secondary" >
                Stări
            </Button>
        </Grid>
      </Grid>

      <Grid container spacing={2} className="mt-1">
        <Grid item xs={12} md={6} style={{marginTop:'6px'}}>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ro}>
              <DatePicker
                  label="Dată finalizare"
                  value={dataFinalizare}
                  onChange={(newValue) => {
                      if (!newValue) {
                          setDataFinalizare(null);
                      } else {
                          setDataFinalizare(new Date(newValue));
                      }
                  }}
                  renderInput={(params) => <TextField {...params} fullWidth margin="dense" size="small" />}
                  inputFormat="dd.MM.yyyy"
                  className='inputMic'
                  disabled={!isFinalizat}
              />
          </LocalizationProvider>
        </Grid>

        <Grid item xs={12} md={6} style={{marginTop:'6px'}}>
          <FormControl fullWidth>
            <InputLabel id="solutie-label">Soluție</InputLabel>
            <Select
              labelId="solutie-label"
              id="solutie"
              value={solutie}
              size='small'
              label="Soluție"
              disabled={!idstare || !stari.find(stare => stare.id === idstare)?.denumire.toLowerCase().includes('finalizat')}
              onChange={handleSolutieChange}
            >
              <MenuItem value="admis">Admis</MenuItem>
              <MenuItem value="respins">Respins</MenuItem>
              <MenuItem value="admis partial">Admis parțial</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField style={{marginTop:'6px'}}
            label="Descriere soluție dosar"
            multiline
            rows={2}
            size='small'
            value={descriereSolutie}
            disabled={!idstare || !stari.find(stare => stare.id === idstare)?.denumire.toLowerCase().includes('finalizat')}
            onChange={(e) => setDescriereSolutie(e.target.value)}
            fullWidth
          />
        </Grid>
      </Grid> 

      <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="xs" fullWidth>
        <DialogTitle className='titluModal'>Administrare stări dosare</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} alignItems="flex-end">
            <Grid item xs={10}>
              <TextField
                margin="dense"
                label="Adăugare tip stare"
                type="text"
                fullWidth
                variant="outlined"
                value={newDenumire}
                onChange={(e) => setNewDenumire(e.target.value)}
                error={!!error}
                helperText={error}
                size="small"
                onKeyPress={(e) => { if (e.key === 'Enter') handleAddStare(); }}
              />
            </Grid>
            <Grid item xs={2}>
              <IconButton onClick={handleAddStare} color="primary">
                {addLoading ? <CircularProgress size={24} /> : <CheckIcon />}
              </IconButton>
            </Grid>
          </Grid>
          <TableContainer component={Paper} style={{ maxHeight: 440 }}>
            <Table stickyHeader className='tabelStari'>
              <TableHead>
                <TableRow>
                  <TableCell>Denumire</TableCell>
                  <TableCell>Acțiune</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {stari.map((stare) => (
                  <TableRow key={stare.id}>
                    <TableCell>
                      {editMode && stareToEdit.id === stare.id ? (
                        <TextField
                          margin="dense"
                          value={newDenumireEdit} 
                          error={!!error}
                          helperText={error}
                          fullWidth
                          onKeyPress={(e) => { if (e.key === 'Enter') handleAddOrUpdateStare(); }}
                          onChange={handleDenumireChange}
                        />
                      ) : (
                        capitalizeFirstLetter(stare.denumire)
                      )}
                    </TableCell>
                    <TableCell>
                    {editMode && stareToEdit.id === stare.id ? (
                          <>
                            <IconButton onClick={handleAddOrUpdateStare} color="primary">
                              <CheckIcon />
                            </IconButton>
                            <IconButton onClick={handleCancelEdit} color="secondary">
                              <CloseIcon /> 
                            </IconButton>
                          </>
                        ) : (
                        <>
                          <IconButton onClick={() => handleEditare(stare)}>
                            <EditIcon />
                          </IconButton>
                          <IconButton onClick={() => handleDeleteStare(stare.id)}>
                            <DeleteIcon />
                          </IconButton>
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Renunțare
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={adminModalOpen} onClose={handleAdminModalClose}>
        <DialogTitle className='titluModal'>{currentAdminObiect ? "Editare Obiect" : "Adăugare Obiect Nou"}</DialogTitle>
        <DialogContent className='mt-3'>
            <textarea
            minRows={3}
            style={{ width: '100%' }}
            value={textareaValue}
            onChange={handleTextareaChange}
            ></textarea>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => handleSaveAdminObiect()}  disabled={!textareaValue.trim()}
 color="primary">
            Salvare
            </Button>
            <Button onClick={handleAdminModalClose} color="primary">
            Anulare
            </Button>
        </DialogActions>
        </Dialog>

      </DialogContent>
      <DialogActions>
      {!loadingInfo && ( <Button onClick={() => { resetFields(); onClose(); }}  color="error">
          Anulare
        </Button> )}
        <Button
          disabled={loadingInfo}
          onClick={handleCreareDosar}
          color="success"
          variant="contained"
          size="small"
        >
          {loadingInfo ? "Vă rugăm așteptați... Se crează dosarul." : "Creare Dosar"}
        </Button>
        {loadingInfo && (
          <Backdrop
            style={{ position: 'absolute', color: '#fff', zIndex: 1 }}
            open
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
      </DialogActions>
    </Dialog>
  );
  
  
}

export default CreareDosar;