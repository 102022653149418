import React, { useEffect, useState } from 'react'; 
import { Box, Tabs, Tab, Button, Checkbox, TextField, LinearProgress, FormControlLabel, Typography, Alert, Autocomplete, Pagination } from '@mui/material';
import { LicenseInfo } from '@mui/x-license-pro';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import ReplayIcon from '@mui/icons-material/Replay';
import * as XLSX from 'xlsx';
import verificareToken from '../VerificareToken';
import FileUpload from '../FileUpload'; 

LicenseInfo.setLicenseKey(
  'ac62ac97b94addeb6dbf2b2473e35ecbTz05MjE3MSxFPTE3NDk2MjQ2NjIwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
);

const ImportManualNrintern = () => {
  const { token } = verificareToken();
  const [value, setValue] = useState(0);
  const [step, setStep] = useState(1);
  const [dosareList, setDosareList] = useState([]);
  const [checkedList, setCheckedList] = useState([]);
  const [selectedUsersList, setSelectedUsersList] = useState([]);
  const [observatiiList, setObservatiiList] = useState([]);
  const [users, setUsers] = useState([]);
  const [progress, setProgress] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 100;
  const [importedList, setImportedList] = useState([]);
 
  const handleReset = () => {
    setStep(1); 
    setDosareList([]);
    setCheckedList([]);
    setImportedList([]);
    setProgress(0); 
    setSelectedUsersList([]);
  };

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await fetch(`${window.API_URL}users?users_from_all_common_groups=1`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        });
        const { data } = await response.json();
        setUsers(data.users);
      } catch (error) {
        console.error("Eroare la preluarea utilizatorilor", error);
      }
    };

    fetchUsers();
  }, [token]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleFileUpload = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const rows = XLSX.utils.sheet_to_json(sheet);
  
      const regex = /^\d+\/\d+\/\d+(?:\*|(?:\/\w+(?:\.\d+)?(?:\*)?))?(?:\/\w+(?:\.\d+)?|\*\*\/\w+|\*\*|\/\w+\*\*?)?|^\d+\/\d+\/\d+\*{4}$/;
      const list = rows
        .map((row, index) => ({
          nrCrt: index + 1,
          numarDosar: row['Numar Dosar'],
          numarIntern: row['Numar Intern'] || null,
          observatii: row['Observatii'] || ''
        }))
        .filter(item => item.numarDosar && regex.test(item.numarDosar));
  
      setDosareList(list);
      setCheckedList(Array(list.length).fill(true));
      setSelectedUsersList(Array(list.length).fill(null));
      setObservatiiList(list.map(item => item.observatii));
    };
    reader.readAsArrayBuffer(file);
  };

  const handleNextStep = () => {
    if (step === 2) {
      setProgress(0);
      importDosare();
    }
    setStep(step + 1);
  };

  const handlePrevStep = () => {
    setStep(step - 1);
  };

  const handleCheckboxChange = (index) => {
    const updatedCheckedList = [...checkedList];
    updatedCheckedList[index] = !updatedCheckedList[index];
    setCheckedList(updatedCheckedList);
  };

  const handleUserChange = (event, newValue, index = null) => {
    if (index === null) {
      setSelectedUsersList(selectedUsersList.map(() => newValue));
    } else {
      const globalIndex = (currentPage - 1) * pageSize + index;
      const updatedSelectedUsersList = [...selectedUsersList];
      updatedSelectedUsersList[globalIndex] = newValue;
      setSelectedUsersList(updatedSelectedUsersList);
    }
  };

  const handleObservatiiChange = (event, index) => {
    const globalIndex = (currentPage - 1) * pageSize + index;
    const updatedObservatiiList = [...observatiiList];
    updatedObservatiiList[globalIndex] = event.target.value;
    setObservatiiList(updatedObservatiiList);
  };

  const importDosare = async () => {
    const selectedDosare = dosareList.filter((_, index) => checkedList[index]);
    const totalDosare = selectedDosare.length;
    const currentUserId = localStorage.getItem('iduser');
    let totalProcessed = 0;
  
    for (const [index, dosar] of selectedDosare.entries()) {
      const { numarDosar, numarIntern, observatii } = dosar;
      const idUserDosar = selectedUsersList[index]?.id || currentUserId;
  
      try {
        const response = await fetch(`${window.API_URL}importByNumarDosar`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
          body: JSON.stringify({
            numarDosar,
            numarIntern,
            observatii,
            idUserDosar: idUserDosar,
          }),
        });
        const success = response.status === 200;
  
        setImportedList((prev) => [
          ...prev,
          { numarDosar, numarIntern, observatii, success },
        ]);
      } catch {
        setImportedList((prev) => [
          ...prev,
          { numarDosar, numarIntern, observatii, success: false },
        ]);
      }
  
      totalProcessed++;
      setProgress(Math.floor((totalProcessed / totalDosare) * 100));
    }
  };
  

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const currentPageData = dosareList.slice((currentPage - 1) * pageSize, currentPage * pageSize);

  return (
    <>
      <Box sx={{ display: 'flex', height: '100%' }}>
 
        <Box sx={{ flex: 1, p: 3 }}>
          {step === 1 && (
            <Box>
              <Alert severity="info" variant="standard" sx={{ mb: 2 }}> 
                  Această zonă vă permite să importați dosarele dintr-un fișier Excel. 
                  <br />
                  Pentru fiecare dosar din listă:
                  <ul>
                    <li>- Se va atribui <strong>Numărul Intern</strong>, dacă este specificat.</li>
                    <li>- Se vor prelua și <strong>Observațiile</strong>, dacă există.</li>
                  </ul> <br></br>
                  Asigurați-vă că fișierul Excel conține coloanele necesare: <strong>"Numar Dosar"</strong>, <strong>"Numar Intern"</strong> și <strong>"Observatii"</strong>, după caz.
                  </Alert>
              <FileUpload onFileUpload={handleFileUpload} />
              {dosareList.length > 0 && (
                <Alert severity="info" sx={{ mt: 2 }}>
                  Fișier procesat cu succes: {dosareList.length} dosare identificate.
                </Alert>
              )}
              <Button
                variant="contained"
                color="primary"
                onClick={handleNextStep}
                disabled={dosareList.length === 0}
                sx={{ mt: 2 }}
              >
                Continuare <ArrowForwardIcon />
              </Button>
            </Box>
          )}

          {step === 2 && (
            <Box>
              <Typography variant="h6" gutterBottom>
                Dosare de importat
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkedList.every(Boolean)}
                      onChange={() => {
                        const allChecked = checkedList.every(Boolean);
                        setCheckedList(checkedList.map(() => !allChecked));
                      }}
                    />
                  }
                  label="Toate"
                />
                <Autocomplete
                  options={users}
                  getOptionLabel={(option) => option.name}
                  value={null}
                  onChange={(event, newValue) => handleUserChange(event, newValue)}
                  renderInput={(params) => <TextField {...params} label="Selectați utilizatorul pentru toate dosarele" variant="outlined" />}
                  size='small'
                  sx={{ ml: 2, flex: 1 }}
                />
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2, mb: 2 }}>
                <Pagination
                        count={Math.ceil(dosareList.length / pageSize)}
                        page={currentPage}
                        onChange={handlePageChange}
                        sx={{ mt: 2 }}
                      />

              </Box>
              {currentPageData.map((item, index) => {
                const globalIndex = (currentPage - 1) * pageSize + index;
                return (
                  <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                    <Typography sx={{ width: '50px' }}>{item.nrCrt}.</Typography>
                    <Checkbox
                      checked={checkedList[globalIndex]}
                      onChange={() => handleCheckboxChange(globalIndex)}
                    />
                    <Typography sx={{ flex: 1 }}>{item.numarDosar} ({item.numarIntern || 'Fără număr intern'})</Typography>
                    <Autocomplete
                      options={users}
                      getOptionLabel={(option) => option.name}
                      value={selectedUsersList[globalIndex]}
                      onChange={(event, newValue) => handleUserChange(event, newValue, index)}
                      renderInput={(params) => <TextField {...params} label="Selectați utilizatorul" variant="outlined" />}
                      size='small'
                      sx={{ ml: 2, flex: 1 }}
                    />
                    <TextField
                      value={observatiiList[globalIndex]}
                      onChange={(event) => handleObservatiiChange(event, index)}
                      label="Observații"
                      variant="outlined"
                      size="small"
                      sx={{ ml: 2, flex: 1 }}
                    />
                  </Box>
                );
              })}
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2, mb: 2 }}>
                <Pagination
                  count={Math.ceil(dosareList.length / pageSize)}
                  page={currentPage}
                  onChange={handlePageChange}
                  sx={{ mt: 2 }}
                />
              </Box>
              <Alert severity="warning">
                <b>{checkedList.filter(Boolean).length}</b> dosare selectate
              </Alert>
              <Button variant="contained" onClick={handlePrevStep} sx={{ mt: 2, mr: 2 }}>
                <ArrowBackIcon /> Înapoi
              </Button>
              <Button variant="contained" color="success" onClick={handleNextStep} sx={{ mt: 2 }}>
                Importare <SaveAltIcon />
              </Button>
            </Box>

          )}

{step === 3 && (
  <Box component="div">
    {progress < 100 ? (
      <Alert severity="warning" style={{ marginBottom: '20px' }}>
        Importul dosarelor este în curs de realizare, vă rugăm să așteptați...
      </Alert>
    ) : (
      <Alert severity="success" style={{ marginBottom: '20px' }}>
        Importul dosarelor a fost finalizat cu succes!
      </Alert>
    )}
    <Typography variant="body1" sx={{ mb: 2 }}>
    {progress < 100
  ? `Progres import: ${progress}% (${importedList.length} / ${checkedList.filter(Boolean).length} dosare procesate)`
  : `Import finalizat: ${importedList.length} / ${checkedList.filter(Boolean).length} dosare procesate.`}

    </Typography>
    <LinearProgress
      variant="determinate"
      value={progress}
      sx={{
        height: '20px',
        mb: 2,
        bgcolor: progress === 100 ? 'success.light' : 'primary.light',
        '& .MuiLinearProgress-bar': {
          bgcolor: progress === 100 ? 'success.main' : 'primary.main',
        },
      }}
    />
    {progress === 100 && (
      <Box sx={{ mt: 2 }}>
        {importedList.map((item, index) => (
          <Box key={index} display="flex" alignItems="center" sx={{ mb: 1 }}>
            <Typography variant="body2" sx={{ flex: 1 }}>
              {item.numarDosar}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: item.success ? 'green' : 'red',
                fontWeight: 'bold',
              }}
            >
              {item.success
                ? `✔️ Succes${
                    selectedUsersList[index]
                      ? ` pentru utilizatorul ${selectedUsersList[index].fullname} (${selectedUsersList[index].name})`
                      : ''
                  }`
                : '❌ Eșec - Dosarul nu este valid sau nu există.'}
            </Typography>
          </Box>
        ))}
        <Typography variant="body1" sx={{ mt: 2, mb: 2 }}>
          Rezumat:
        </Typography>
        <Typography variant="body2" sx={{ color: 'green', mb: 1 }}>
          ✔️ Dosare importate cu succes: {importedList.filter((item) => item.success).length}
        </Typography>
        <Typography variant="body2" sx={{ color: 'red', mb: 2 }}>
          ❌ Dosare nereușite: {importedList.filter((item) => !item.success).length}
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
          <Button color="warning" variant="contained" onClick={handlePrevStep}>
            <ArrowBackIcon /> Înapoi
          </Button>
          <Button color="primary" variant="contained" onClick={handleReset}>
            Realizare import nou <ReplayIcon />
          </Button>
          <Button color="success" variant="contained" href="/lista-dosare">
            Listă dosare
          </Button>
        </Box>
      </Box>
    )}
  </Box>
)}

        </Box>
      </Box>
    </>
  );
};

export default ImportManualNrintern;
